@import  "../../App.scss";

.careers{
    margin-bottom: 5% !important;
    h1{
        font-size: 3em;
        font-weight: 500;
        margin-bottom: 2%;
        text-align: center;
    }
    width: 30%;
    margin: auto;
}

@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
    .careers{
        margin-bottom: 5% !important;
        h1{
            font-size: 1.5em;
            font-weight: 500;
            margin-bottom: 2%;
            text-align: center;
        }
        width: 80%;
        margin: auto;
    }
}
