@import  "../../App.scss";


.multimedia{
    .info{
        background-color: rgb(240, 255, 239);
        padding: 1% 2%;
        h3{
            font-size: 1.8em;
            font-weight: 400;
        }
        p{
            line-height: 1.5;
        }
        
    }
    
}