@import  "../../App.scss";

.overview{
    padding: 0 4% 5% 4%;
    
    h1{
        font-size: 3em;
        font-weight: 500;
        margin-bottom: 2%;
        text-align: center;
    }
    img{
        width: 100%;
        border-radius: 10px;
        border: 5px solid $tertiary;
    }
   .row-cont{
    width: 90%;
    .info-col{
        background-color: rgb(240, 255, 239);
        padding: 1% 2%;
        border-radius: 10px;

    }
    h3{
        font-weight: 500;
        font-size: 1.8em;
    }
    p{
        line-height: 2;
    }
    
   }

   .vision-col{
    text-align: center;
    img{
        border: none;
        width: 30%;
    }
    h3{
        font-size: 2em;
        font-weight: 400;
    }
    p{

        text-align: justify;
    }
   }

   ol{
    li{
        color: black;
    }
   }

}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
.overview{
    img{
        margin: 8% 0 !important;
    }
}
}
