$primary:#019267;
$secondary:#ccc;
$tertiary:#00f7ad;
$primary-font:'Montserrat', sans-serif;
$secondary-font:'Roboto', sans-serif;

h1,h2,h3,h5{
  font-family: $primary-font
}


.color-span{
  color: $primary;
}

body{
  font-family: $secondary-font;
}
.gradient-color{
  background: -webkit-linear-gradient($tertiary, #55a78e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-color-dark{
  background: -webkit-linear-gradient(#004d36, #478a76);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
::-webkit-scrollbar{
  width:5px !important;
}

::-webkit-scrollbar-track{
  background:transparent !important;
}


::-webkit-scrollbar-thumb{
  background:rgb(235, 235, 235) !important;
  opacity:0.2;
  border-radius:25px
}

::-webkit-scrollbar-thumb:hover{
  background:#5a1e96 !important;
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
  .alert{
    position: absolute;
    bottom: 0;
    z-index: 5;
    left: 0%;
  }
  
}