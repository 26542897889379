@import  "../../App.scss";

.rdcrams{
    padding-bottom: 5%;
    width: 70%;
    margin: auto;
    img{
        width: 15%;
        margin: auto;
        display: block;
    }
    h1{
        font-size: 3em;
        font-weight: 500;
        margin-bottom: 2%;
        text-align: center;
    }
    p{
        text-align: center;
    }
}