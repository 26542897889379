@import  "../../App.scss";

footer{
    background-color: #018f6444;
    color: black;
    padding: 3% 2%;
    .makeinindialogo{
        width: 50%;
        border-radius: 10px;
        

    }
    img{
        width: 100%;
    }
    h1{
        font-size: 2em;
        font-weight: 700;

    }
    h4{
        font-size: 1.8em;
        font-weight: 400;
    }
    .quote{
        font-size: 1.3em;
        font-weight: 300;
    }
    p{
        font-weight: 300;
    }
    .link{
        text-decoration: underline;
        color: black;
        font-size: 1.1em;
    }
    .link:hover{
        color: $primary;
        transition: .2s;
    }
}


@media (max-width: 992px) {

}
@media (max-width: 768px) {


}
@media (max-width: 576px) {
footer{
    padding: 8% 5%;
    .col-6{
        margin: 8% 0;
    }
    .footer-bottom-cont{
        margin: 4% 0 !important;
    }
}
}

